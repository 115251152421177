function insertSelect(dados) {
    let option = `
        <option value="${dados.id}">${dados.nome}</option>
        `;
    $(".note-gallery").append(option);
}

function paginacao(proximo = '#', anterior = '#') {
    let prox = (!proximo ? "#" : proximo);
    let ant = (!anterior ? "#" : anterior);
    let disable_prox = null, disable_ant = null;
    if (!anterior) {
        disable_ant = 'class="disabled"'
    }
    if (!proximo) {
        disable_prox = 'class="disabled"';
    }
    return `
        <nav>
            <ul class="pager">
                <li ${disable_prox}><a href="${prox}">Anterior</a></li>
                <li ${disable_ant}><a href="${ant}">Próximo</a></li>
            </ul>
        </nav>
        `;
}

function addStyle(seletor) {
    seletor.parents('.col-sm-6.col-md-2').animate({'opacity': '0.3'}, 500);
    $('#spinner').html('<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>').css({
        'position': 'absolute',
        'top': seletor.offset().top - 180,
        'left': seletor.offset().left - 235,
        'z-index': 100
    });
}

function removeElemento(seletor) {
    seletor.parents('.col-sm-6.col-md-2').animate({'opacity': '0'}, 1000);
    setTimeout(function () {
        seletor.parents('.col-sm-6.col-md-2').remove();
        contaSeletor();
    }, 1500);
}

function removeStyle(seletor) {
    $('#spinner').children().remove();
    seletor.parents('.col-sm-6.col-md-2').animate({'opacity': '1'}, 2000);
}

function contaSeletor() {
    var quantidade = $('#imagens .col-sm-6.col-md-2').length;
    $('#titulo-galeria span').text('(' + quantidade + ')');
}

function AddTableRow(seletor, dado) {
    let col = `
        <tr>
            <td><img src="${dado.link}?w=50&h=50&fit=crop" class="center-block" alt="${dado.nome}"></td>
            <td>${dado.descricao}</td>
            <td>
                <div class="input-group">
                    <input data-creditos="${dado.creditos}" type="text" value="${dado.link}" class="form-control">
                    <span class="input-group-btn">
                        <button class="btn btn-success linkImagem" type="button"><i class="fa fa-plus"></i></button>
                    </span>
                </div>
            </td>
        </tr>
        `;
    $(seletor).append(col);
    return false;
}

function AddTableRowCategoria(seletor, dado) {
    let col = `
        <tr>
            <td>${dado.nome}</td>
            <td>
                <div class="input-group">
                    <input type="text" value="${dado.link_categoria}" class="form-control">
                    <span class="input-group-btn">
                        <button class="btn btn-success linkCategoria" type="button"><i class="fa fa-plus"></i></button>
                    </span>
                </div>
            </td>
        </tr>
        `;
    $(seletor).append(col);
    return false;
}

function AddTableRowPagina(seletor, dado) {
    let col = `
        <tr>
            <td>${dado.titulo}</td>
            <td>
                <div class="input-group">
                    <input type="text" value="${dado.link_pagina}" class="form-control">
                    <span class="input-group-btn">
                        <button class="btn btn-success linkCategoria" type="button"><i class="fa fa-plus"></i></button>
                    </span>
                </div>
            </td>
        </tr>
        `;
    $(seletor).append(col);
    return false;
}

function AddTableRowSlide(seletor, dado) {
    let col = `
        <tr>
            <td class="text-center">${dado.id}</td>
            <td><img src="${dado.imagem}?w=60&h=60&fit=crop" class="center-block" alt="${dado.nome}"></td>
            <td class="text-center">${dado.url}</td>
            <td><span class="label label-success">Publicado</span></td>
            <td>
                <div class="input-group">
                    <input data-creditos="${dado.creditos}" type="text" value="${dado.link}" class="form-control">
                    <span class="input-group-btn">
                        <button class="btn btn-success linkImagem" type="button"><i class="fa fa-plus"></i></button>
                    </span>
                </div>
            </td>
        </tr>
        `;
    $(seletor).append(col);
    return false;
}

function strip_tags(str) {
    str = str.toString();
    return str.replace(/<\/?[^>]+>/gi, '');
}